import React, { useState, useRef, useEffect } from "react";
import { news, events } from "./data";

import "./leftsidebar.css";

const LeftSideBar = () => {
  const linksRef = useRef(null);
  return (
    <>
      <div className="nav2">
        <ul className="news" ref={linksRef}>
          <li className="header1"> Lastest News</li>
          {news.map((news) => {
            const { id, url, text } = news;
            return (
              <li key={id}>
                <a href={url}>{text}</a>
              </li>
            );
          })}
          <li className="header2"> Lastest Events</li>
          {events.map((events) => {
            const { id, url, text } = events;
            return (
              <li key={id}>
                <a href={url}>{text}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default LeftSideBar;
