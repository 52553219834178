import React, { Component } from "react";
import { Link } from "react-router-dom";

const Navboximage = ({
  url,
  image_file,
  class_name = "nav-item",
  element_type = "none",
  character_weapon = "none",
}) => {
  return (
    <div className={class_name}>
      <div
        className="item-seperator"
        className={element_type}
        className={character_weapon}
      />
      <Link to={url} className="nav-items-image">
        <img src={image_file} />{" "}
      </Link>
    </div>
  );
};

export default Navboximage;
