import React, { Component, useState } from "react";

const Imagetog = ({ image_file, class_name = "image-icon" }) => {
  const [switch1, setSwitch] = useState(false);

  return (
    <div
      onClick={() => {
        setSwitch(!switch1);
      }}
      className={class_name}
    >
      <div className={switch1 ? "not-filter" : "filter"} />

      <img src={image_file} />
    </div>
  );
};

export default Imagetog;
