import React, { Component } from "react";

import Navboximage from "./Navboximage";
import Imagetog from "./Imagetog";

class Characters extends Component {
  render() {
    return (
      <div className="main">
        <h1>All Characters</h1>
        <div className="elements">
          <Imagetog image_file="/images/elements/pyro.png" />
          <Imagetog image_file="/images/elements/electro.png" />
          <Imagetog image_file="/images/elements/hydro.png" />
          <Imagetog image_file="/images/elements/cyro.png" />
          <Imagetog image_file="/images/elements/anemo.png" />
          <Imagetog image_file="/images/elements/geo.png" />
          <Imagetog image_file="/images/elements/dendro.png" />
        </div>
        <div id="characters">
          <Navboximage
            image_file="/images/characters_icon/amber.jpg"
            element_type="pyro"
            character_weapon="bow"
            class_name="nav-item-image"
            url="/"
          />
          <Navboximage
            image_file="/images/characters_icon/barbara.jpg"
            element_type="hydro"
            character_weapon="book"
            class_name="nav-item-image"
            url="/"
          />
          <Navboximage
            image_file="/images/characters_icon/beidou.jpg"
            element_type="electro"
            character_weapon="claymore"
            class_name="nav-item-image"
            url="/"
          />
          <Navboximage
            image_file="/images/characters_icon/bennett.jpg"
            element_type="pyro"
            character_weapon="sword"
            class_name="nav-item-image"
            url="/"
          />
          <Navboximage
            image_file="/images/characters_icon/chongyun.jpg"
            element_type="cyro"
            character_weapon="claymore"
            class_name="nav-item-image"
            url="/"
          />
          <Navboximage
            image_file="/images/characters_icon/diluc.jpg"
            element_type="pyro"
            character_weapon="claymore"
            class_name="nav-item-image"
            url="/"
          />
          <Navboximage
            image_file="/images/characters_icon/diona.jpg"
            class_name="nav-item-image"
            url="/"
          />
          <Navboximage
            image_file="/images/characters_icon/fischl.jpg"
            element_type="electro"
            character_weapon="bow"
            class_name="nav-item-image"
            url="/"
          />
        </div>
      </div>
    );
  }
}

export default Characters;
