import "./App.css";
import NavBar from "./components/navbar/NavBar";
import LeftSideBar from "./components/leftsidebar/LeftSideBar";
import Main from "./components/main/Main";
import RightSideBar from "./components/rightsidebar/RightSideBar";
import Cover from "./components/cover/Cover";
import Footer from "./components/footer/Footer";
import TopAds from "./components/topads/TopAds";
import TierList from "./components/tierlist/TierList";
import Characters from "./components/characters/Characters";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <div className="section">
        <Router>
          <NavBar />
          <Cover />
          <TopAds />
          <LeftSideBar />
          <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/tierlist" exact component={TierList} />
            <Route path="/characters" exact component={Characters} />
          </Switch>
          <RightSideBar />
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
