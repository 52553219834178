import React from "react";

export const charactersData = [
  {
    id: 1,
    name: "Amber",
    img_char: "/images/characters_icon/amber.jpg",
    element_type: "pyro",
    character_weapon: "bow",
    url: "/characters/amber",
    tier: "C",
  },
  {
    id: 2,
    name: "Barbara",
    img_char: "/images/characters_icon/barbara.jpg",
    element_type: "hydro",
    character_weapon: "catalyst",
    url: "/characters/barbara",
    tier: "B",
  },
  {
    id: 3,
    name: "Bennett",
    img_char: "/images/characters_icon/bennett.jpg",
    element_type: "pyro",
    character_weapon: "sword",
    url: "/characters/bennett",
    tier: "S",
  },
  {
    id: 4,
    name: "Beidou",
    img_char: "/images/characters_icon/beidou.jpg",
    element_type: "electro",
    character_weapon: "claymore",
    url: "/characters/beidou",
    tier: "B",
  },

  {
    id: 5,
    name: "Chongyun",
    img_char: "/images/characters_icon/chongyun.jpg",
    element_type: "cyro",
    character_weapon: "claymore",
    url: "/characters/chongyun",
    tier: "A",
  },
  {
    id: 6,
    name: "Venti",
    img_char: "/images/characters_icon/venti.jpg",
    element_type: "anemo",
    character_weapon: "bow",
    url: "/characters/venti",
    tier: "SS",
  },
  {
    id: 7,
    name: "diluc",
    img_char: "/images/characters_icon/diluc.jpg",
    element_type: "pyro",
    character_weapon: "claymore",
    url: "/characters/diluc",
    tier: "S",
  },
  {
    id: 8,
    name: "Diona",
    img_char: "/images/characters_icon/diona.jpg",
    element_type: "cyro",
    character_weapon: "catalyst",
    url: "/characters/diona",
    tier: "A",
  },
  {
    id: 9,
    name: "Fischl",
    img_char: "/images/characters_icon/fischl.jpg",
    element_type: "elctro",
    character_weapon: "bow",
    url: "/characters/fischl",
    tier: "A",
  },
  {
    id: 10,
    name: "Jean",
    img_char: "/images/characters_icon/jean.jpg",
    element_type: "anemo",
    character_weapon: "sword",
    url: "/characters/jean",
    tier: "A",
  },
  {
    id: 11,
    name: "Kaeya",
    img_char: "/images/characters_icon/kaeya.jpg",
    element_type: "cyro",
    character_weapon: "sword",
    url: "/characters/kaeya",
    tier: "B",
  },
  {
    id: 12,
    name: "Keqing",
    img_char: "/images/characters_icon/keqing.jpg",
    element_type: "electro",
    character_weapon: "sworld",
    url: "/characters/keqing",
    tier: "A",
  },
  {
    id: 13,
    name: "Klee",
    img_char: "/images/characters_icon/klee.jpg",
    element_type: "pyro",
    character_weapon: "catalyst",
    url: "/characters/klee",
    tier: "S",
  },
  {
    id: 14,
    name: "Lisa",
    img_char: "/images/characters_icon/lisa.jpg",
    element_type: "electro",
    character_weapon: "catalyst",
    url: "/characters/lisa",
    tier: "C",
  },
  {
    id: 15,
    name: "Mona",
    img_char: "/images/characters_icon/mona.jpg",
    element_type: "hydro",
    character_weapon: "catalyst",
    url: "/characters/mona",
    tier: "S",
  },
  {
    id: 16,
    name: "Ningguang",
    img_char: "/images/characters_icon/ningguang.jpg",
    element_type: "geo",
    character_weapon: "catalyst",
    url: "/characters/ningguang",
    tier: "A",
  },
  {
    id: 17,
    name: "Noelle",
    img_char: "/images/characters_icon/noelle.jpg",
    element_type: "geo",
    character_weapon: "claymore",
    url: "/characters/noelle",
    tier: "B",
  },
  {
    id: 18,
    name: "Qiqi",
    img_char: "/images/characters_icon/qiqi.jpg",
    element_type: "cyro",
    character_weapon: "sword",
    url: "/characters/qiqi",
    tier: "S",
  },
  {
    id: 19,
    name: "Razor",
    img_char: "/images/characters_icon/razor.jpg",
    element_type: "electro",
    character_weapon: "claymore",
    url: "/characters/razor",
    tier: "A",
  },
  {
    id: 20,
    name: "Sucrose",
    img_char: "/images/characters_icon/sucrose.jpg",
    element_type: "anemo",
    character_weapon: "catalyst",
    url: "/characters/sucrose",
    tier: "A",
  },
  {
    id: 21,
    name: "Tartaglia",
    img_char: "/images/characters_icon/tartaglia.jpg",
    element_type: "hydro",
    character_weapon: "bow",
    url: "/characters/tartaglia",
    tier: "S",
  },
  {
    id: 22,
    name: "Traveller (Geo)",
    img_char: "/images/characters_icon/traveller.jpg",
    element_type: "geo",
    character_weapon: "sword",
    url: "/characters/travellergeo",
    tier: "C",
  },
  {
    id: 23,
    name: "Traveller (Anemo)",
    img_char: "/images/characters_icon/traveller.jpg",
    element_type: "anemo",
    character_weapon: "sword",
    url: "/characters/travelleranemo",
    tier: "C",
  },
  {
    id: 24,
    name: "Xiangling",
    img_char: "/images/characters_icon/xiangling.jpg",
    element_type: "pyro",
    character_weapon: "polearm",
    url: "/characters/xiangling",
    tier: "B",
  },
  {
    id: 25,
    name: "xingqiu",
    img_char: "/images/characters_icon/xingqiu.jpg",
    element_type: "hydro",
    character_weapon: "sword",
    url: "/characters/xingqiu",
    tier: "A",
  },
  {
    id: 26,
    name: "Zhongli",
    img_char: "/images/characters_icon/zhongli.jpg",
    element_type: "geo",
    character_weapon: "polearm",
    url: "/characters/zhongli",
    tier: "S",
  },
  {
    id: 27,
    name: "Albedo",
    img_char: "/images/characters_icon/albedo.jpg",
    element_type: "geo",
    character_weapon: "sword",
    url: "/characters/albedo",
    tier: "S",
  },
];
