import React, { useState, useRef, useEffect } from "react";

import "./main.css";

const Main = () => {
  const linksRef = useRef(null);
  return (
    <>
      <div className="main">
        <h1>Genshin Impact - Guide & Walkthrough ></h1>
        <div className="hot">
          <h3>Hot topic</h3>
          <a href="/tierlist"> Latest Tier List</a>
        </div>
      </div>
    </>
  );
};

export default Main;
