import React, { useState, useRef, useEffect } from "react";

import "./rightsidebar.css";

const RightSideBar = () => {
  const linksRef = useRef(null);
  return (
    <>
      <div className="rightsidebar">right sidebar</div>
    </>
  );
};

export default RightSideBar;
