import React, { useState, useRef, useEffect } from "react";

import "./footer.css";

const Footer = () => {
  const linksRef = useRef(null);
  return (
    <>
      <h1>todo</h1>
    </>
  );
};

export default Footer;
