import React, { Component } from "react";
import "./cover.css";

class Cover extends Component {
  render() {
    return (
      <div id="cover">
        <h1>Genshin impact - Guide & Event & Data-base</h1>
      </div>
    );
  }
}

export default Cover;
