export const news = [
  {
    id: 1,
    url: "/",
    text: "news 1",
  },
  {
    id: 2,
    url: "/about",
    text: "news 2",
  },
  {
    id: 3,
    url: "/projects",
    text: "news 3",
  },
  {
    id: 4,
    url: "/contact",
    text: "news 4",
  },
  {
    id: 5,
    url: "/profile",
    text: "news 5",
  },
];

export const events = [
  {
    id: 1,
    url: "/",
    text: "events 1",
  },
  {
    id: 2,
    url: "/",
    text: "events 2",
  },
  {
    id: 3,
    url: "/",
    text: "events 3",
  },
  {
    id: 4,
    url: "/",
    text: "events 4",
  },
];
