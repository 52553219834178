import React, { Component } from "react";
import { links } from "../navbar/data";
import { charactersData } from "./data";
import "./tierlist.css";
class TierList extends Component {
  render() {
    const Tier = ["SS", "S", "A", "B", "C"];
    return (
      <div className="main">
        <div className="tierlist">
          <h2>Tier list | January 2021</h2>
          <p>
            Latest tier list ranking of all characters available in Genshin
            impact as of January 2021.
          </p>
          <h3>Overall Tier list</h3>
          {Tier.map((tier) => (
            <>
              <div className="tier-wrapper" id={tier}>
                <div className="tier">
                  <h3 className="ss">{tier}</h3>
                </div>
                <ul>
                  {charactersData
                    .filter((character) => character.tier === tier)
                    .map((character) => {
                      const {
                        id,
                        name,
                        img_char,
                        element_type,
                        character_weapon,
                        url,
                      } = character;
                      return (
                        <li key={id}>
                          <a href={url}>
                            <img src={img_char} alt="" />
                          </a>
                          <h5>{name}</h5>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="gap1"></div>
            </>
          ))}
        </div>
      </div>
    );
  }
}

export default TierList;
