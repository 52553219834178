import React from "react";
import { FaBehance, FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
export const links = [
  {
    id: 1,
    url: "/",
    text: "home",
  },
  {
    id: 2,
    url: "/tierlist",
    text: "Tier list",
  },
  {
    id: 3,
    url: "/characters",
    text: "characters",
  },
  {
    id: 4,
    url: "/contact",
    text: "weapons",
  },
  {
    id: 5,
    url: "/profile",
    text: "etcs",
  },
];

export const social = [
  {
    id: 1,
    url: "https://www.twitter.com",
    icon: <FaFacebook />,
  },
  {
    id: 2,
    url: "https://www.twitter.com",
    icon: <FaTwitter />,
  },
  {
    id: 3,
    url: "https://www.twitter.com",
    icon: <FaLinkedin />,
  },
];
