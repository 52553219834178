import React, { Component } from "react";
import "./topads.css";

class TopAds extends Component {
  render() {
    return (
      <div id="topads">
        <h1>Ads</h1>
      </div>
    );
  }
}

export default TopAds;
